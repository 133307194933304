.table {
    line-height: $table-line-height;

    th {
        text-align: center;
        font-weight: 500;
    }

    td {
        text-align: center;
    }

    /*
     * Imitate a plenty of padding-y if the cell content is just one line,
     * but prevent the cell's height from growing if content is wrapped into two lines.
     */
    th, td {
        height: add(2em * $table-line-height, 2 * $table-multiline-cell-virtual-padding-y);
        vertical-align: middle !important;
    }

    &.cell-align-left {
        td {
            text-align: left;
            padding-left: 5px;
        }
    }

    &.table-has-sticky {
        thead, tbody, tr, th, td {
            background-color: inherit;
        }

        &.table-sticky-row {
            & > thead > tr > * {
                position: sticky;
                top: -1px;

                box-shadow: inset 0 1px 0 #c8c8c8, inset 0 -1px 0 #c8c8c8;

                &:first-child {
                    z-index: 1;
                }
            }
        }

        &.table-sticky-col {
            & > * > tr > :first-child {
                position: sticky;
                left: 0;

                box-shadow: inset -1px 0px 0 #c8c8c8;
            }
        }

        .sticky-cell-left-0 {
            position: sticky;
            left: 0;
        }

        .z-1 {
            z-index: 1;
        }
    }

    tr.collapsible-row {
        &:not(.collapsible-row-open) > :first-child {
            @include caret('down');
        }
        &.collapsible-row-open > :first-child {
            @include caret('up');
        }
    }

    th.collapsible-col:not(.collapsible-col-open) {
        @include caret('right');

        &::after {
            vertical-align: 0.055em;
        }
    }
    th.collapsible-col.collapsible-col-open {
        @include caret('left');

        &::before {
            vertical-align: 0.055em;
        }
    }

    tr.collapsible-row > :first-child,
    th.collapsible-col {
        cursor: pointer;
    }

    tr.collapsible-row-open > *,
    .collapsible-col-open {
        background-color: #f9f9f9 !important;
    }
}

.table-layout-fixed {
    table-layout: fixed;
}
