a {
    cursor: pointer;
}

h1 {
    font-weight: bold;
}

.placeholder-text {
    color: $input-placeholder-color;
}

.no-data-available {
    text-align: center;
    font-size: 150%;
    padding: 20px 0;
}
