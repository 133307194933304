@import 'variables';
@import '~bootstrap';
@import '~bootstrap-vue';
@import "~vue-select/src/scss/vue-select.scss";
@import '~vue2-daterange-picker/dist/vue2-daterange-picker.css';
@import 'fonts';
@import 'forms';
@import 'buttons';
@import 'tables';
@import 'theme';
@import 'the_sidebar';
@import 'utils';
@import 'cockpit_layout';
@import 'tile';

html {
    min-height: 100%;
}

body {
    min-height: 100%;
    background-color: #c7d5e8;
}

#cockpitContent .y-scrollable-block-layout {
    display: flex;
    flex-direction: column;
    max-height: calc(var(--vh, 1vh) * 100 - #{$cockpit-layout-content-padding-top} - #{$cockpit-layout-content-padding-bottom});

    & > .y-scrollable-block {
        flex-grow: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        min-height: 240px;
    }

    & > :not(.y-scrollable-block) {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track
{
  background: rgb(224 224 224);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}

#country-rank-modal___BV_modal_body_,
#product-rank-modal___BV_modal_body_,
#open-watch-orders-modal___BV_modal_body_,
#forecasts-modal___BV_modal_body_ {
    padding-top: 0;
}
