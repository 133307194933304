#cockpitSidebar {
    padding: 63px 5px 0 16px;

    .sidebar-logo {
        margin-bottom: 32px;
    }

    a {
        display: inline-block;
        cursor: pointer;
        color: #FFFFFF !important;
        border-bottom-color: transparent;
        border-bottom-style: solid;

        &.selected, &:hover {
            text-decoration: none;
            border-bottom-color: map-get($theme-colors, 'primary');
        }

        &.not-collapsed::after {
            transform: rotate(180deg);
        }
    }

    ul {
        padding: 0;
        list-style: none;
    }

    & > ul > li {
        @include font-size(18);
        font-weight: 500;

        & > a {
            /* 13 + 2 = 15px spacing */
            margin-bottom: 13px;
            border-bottom-width: 2px;
        }

        & > ul > li {
            @include font-size(16);
            font-weight: normal;

            & > a {
                /* 14 + 1 = 15px spacing */
                margin-bottom: 14px;
                border-bottom-width: 1px;
            }
        }
    }
}
