@font-face {
    font-family: 'Gotham';
    font-weight: 100 400;
    src: url('/assets/fonts/GothamBook.otf'), url('/assets/fonts/GothamBook.otf');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 500 600;
    src: url('/assets/fonts/GothamMedium.otf'), url('/assets/fonts/GothamMedium.woff');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 700 900;
    src: url('/assets/fonts/GothamBold.otf'), url('/assets/fonts/GothamBold.woff');
}

.font-weight-semibold {
    font-weight: 600;
}