.btn-group.btn-group-toggle {
    .btn.active {
        border-color: map-get($theme-colors, 'primary');
        background-color: map-get($theme-colors, 'light');
    }
}

.custom-control-label {
    @include font-size(14);
}

.custom-switch {
    /* same as .custom-control-label's height */
    height: add($custom-switch-indicator-size, 2 * $custom-control-indicator-border-width);

    .custom-control-label {
        &::before {
            height: add($custom-switch-indicator-size, 2 * $custom-control-indicator-border-width);
            background-color: #c8c8c8;
            border-color: #c8c8c8;
        }

        &::after {
            top: add(($font-size-base * $line-height-base - $custom-control-indicator-size) / 2, $custom-control-indicator-border-width);
            left: add(-($custom-switch-width + $custom-control-gutter), $custom-control-indicator-border-width);
            background-color: #ffffff;
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &::before {
            background-color: map-get($theme-colors, 'primary');
            border-color: map-get($theme-colors, 'primary');
        }

        &::after {
            transform: translateX(subtract($custom-switch-width - $custom-switch-indicator-size, $custom-control-indicator-border-width * 2));
        }
    }
}

@mixin filter-input-style {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid map-get($theme-colors, 'primary');
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group.input-group-filter-style {
    & > .input-group-prepend > .input-group-text {
        @include filter-input-style;
        color: map-get($theme-colors, 'primary');
        background-color: $input-bg;
    }

    & > .form-control {
        @include filter-input-style;
    }
}

.form-control.calendar-placeholder {
    @include filter-input-style;
    cursor: pointer;
    background-image: url('/assets/icons/calendar--red.svg');
    background-position: center right 10px;
    background-repeat: no-repeat;
    padding-right: 60px;
}

.v-select {
    @include font-size(14);

    & > .vs__dropdown-toggle {
        @include filter-input-style;
        padding: add($input-padding-y, -4px) $input-padding-x $input-padding-y add($input-padding-x, -2px);
        background-color: $input-bg;

        & > .vs__actions {
            & > .vs__clear {
                margin-right: 15px;
            }
            & > .vs__open-indicator {
                fill: map-get($theme-colors, 'primary');
            }
        }
    }

    .vs__dropdown-option {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.daterangepicker {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    .calendar-table {
        tbody > tr:first-child th {
            color: #c8c8c8;
            font-weight: normal;
            text-transform: uppercase;
        }

        .prev > span,
        .next > span {
            border-color: map-get($theme-colors, 'primary');
        }

        .in-range {
            background-color: rgba(map-get($theme-colors, 'primary'), .2);
        }

        .start-date {
            color: $body-color;
            border-radius: 50% 0 0 50%;
        }

        .end-date {
            color: $body-color;
            border-radius: 0 50% 50% 0;
        }

        .start-date.end-date {
            border-radius: 50%;
        }

        .active:hover {
            background-color: map-get($theme-colors, 'primary');
        }
    }
}
