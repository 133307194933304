.font-size-12 {
    @include font-size(12);
}

:root {
    --border-color: #{$border-color};
}

.mh-400 {
    min-height: 400px;
}

.cursor-pointer {
    cursor: pointer;
}
