#cockpitLayoutWrapper {
    width: 100%;
}

#cockpitSidebar {
    width: $cockpit-layout-sidebar-width;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    top: 0;
    left: -$cockpit-layout-sidebar-width; /* hide sidebar */
    background-color: #000000;
    transition: left 0.3s;

    &.sidebar-visible {
        left: 0;
    }

    &.sidebar-visible + #cockpitContent {
        padding-left: $cockpit-layout-sidebar-width + $cockpit-layout-content-padding-x;
    }
}

#cockpitContent {
    min-height: 100vh;
    padding: $cockpit-layout-content-padding-top $cockpit-layout-content-padding-x $cockpit-layout-content-padding-bottom $cockpit-layout-content-padding-x;
    transition: padding 0.3s;
}
