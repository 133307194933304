.btn {
    cursor: pointer;

    &-primary {
        font-weight: 500;
        box-shadow: 0 8px 8px rgba(0, 0, 0, .2);
    }

    &-light {
        border-color: $border-color;
    }

    &-link-regular {
        border: none;
        padding: 0;
        vertical-align: initial;
    }

    &.btn-icon {
        svg, img {
            max-width: 16px;
            max-height: 16px;
        }
    }
}

.btn-group.btn-group-separate {
    flex-wrap: wrap;

    .btn {
        margin-right: 10px;
        margin-bottom: 10px;
        flex-grow: 0;
        border-radius: $btn-border-radius !important;

        &:last-child {
            margin-right: 0;
        }
    }
}

.btn-group-wide {
    .btn {
        min-width: 200px;
    }
}
